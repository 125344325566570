import { ERROR_REQUIRED_QUESTION } from "constants/messages.constant";
import Joi from "joi";
import { UpdateAnswerRequest } from "types/answer";
import { UpdateFeedBackRequest } from "types/feedback";

export const updateFeedbackSchema = Joi.object<UpdateFeedBackRequest>({
  answers: Joi.array()
    .items(
      Joi.object<UpdateAnswerRequest>({
        questionId: Joi.number().optional().messages({
          "number.base": "Question id must be a number",
        }),
        answerOptionId: Joi.number().optional().messages({
          "number.base": "Answer option id must be a number",
        }),
        answerText: Joi.alternatives()
          .try(Joi.string().trim(), Joi.number())
          .optional()
          .messages({
            "string.base": "Answer text must be a string",
            "string.empty": ERROR_REQUIRED_QUESTION,
            "number.base": "Answer text must be a number",
          }),
      })
        .xor("answerOptionId", "answerText")
        .messages({
          "object.missing": ERROR_REQUIRED_QUESTION,
        }),
    )
    .messages({
      "array.base": "Answers must be an array",
      "any.required": "Answers are required",
    }),
});
