import { Routes, Route } from "react-router-dom";
import "./styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import { PAGE_PATHS } from "constants/page-paths";
import {
  CreatefeedBack,
  SendingOutSurvey,
  ToDoFeedbackRequests,
  EditForm,
  Forms,
  CreateForm,
  PageNotFound,
  Home,
  Login,
  Request360Feedback,
  FeedbackForMe,
  Three60Feedback,
  Three60FeedbackList,
  FeedbackForMeList,
  FeedbackIProvidedList,
  FeedbackIProvided,
  PeerFeedbackList,
  PeerFeedback,
  RequestAnytimeFeedback,
  ProactivelyGiveAnytimeFeedback,
  ProactivelyGive360Feedback,
  FeedbackIProvidedUpdate,
} from "pages";
import { ProtectedRoute } from "components/common";
import { LoadingWrapper } from "components/common/layouts";
import { useAuthStore } from "store";
import { useHttpQueryService, useLoading } from "hooks";
import { UserService } from "services";
import { KeycloakService } from "services";
import { useEffect } from "react";

const Router = () => {
  const updateUser = useAuthStore((state) => state.updateUser);

  // Init Keycloak
  const { result: authenticated, isLoading: isKeycloakInitializing } =
    useHttpQueryService({
      request: () =>
        KeycloakService.init({
          onLoad: "check-sso",
          checkLoginIframe: false,
        }),
    });

  // Fetch user profile from BE
  const { isLoading: isUserFetching, result: userProfile } =
    useHttpQueryService({
      request: async () => UserService.getByToken(),
      requestOption: {
        enabled: !!authenticated,
      },
    });

  // Update user in store
  useEffect(() => {
    if (!userProfile) return;

    updateUser(userProfile);
  }, [userProfile]);

  const isLoading = useLoading([isKeycloakInitializing, isUserFetching]);

  return (
    <LoadingWrapper isLoading={isLoading} className="w-screen h-screen">
      <Routes>
        <Route path={PAGE_PATHS.LOGIN} element={<Login />} />
        <Route
          path={PAGE_PATHS.HOME}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FORM}
          element={
            <ProtectedRoute>
              <Forms />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FORM_CREATE}
          element={
            <ProtectedRoute>
              <CreateForm />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.FORM_EDIT}
          element={
            <ProtectedRoute pathName={PAGE_PATHS.FORM_EDIT}>
              <EditForm />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.REQUEST_ADD_360_REVIEWER}
          element={
            <ProtectedRoute>
              <Request360Feedback />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.REQUEST}
          element={
            <ProtectedRoute>
              <ToDoFeedbackRequests />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.REQUEST_SEND_TO_ALL}
          element={
            <ProtectedRoute>
              <SendingOutSurvey />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.REQUEST_ANYTIME_FEEDBACK}
          element={
            <ProtectedRoute>
              <RequestAnytimeFeedback />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.FEEDBACK_PROACTIVELY_GIVE_ANYTIME}
          element={
            <ProtectedRoute>
              <ProactivelyGiveAnytimeFeedback />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.FEEDBACK_PROACTIVELY_GIVE_THREE60}
          element={
            <ProtectedRoute>
              <ProactivelyGive360Feedback />
            </ProtectedRoute>
          }
        />

        <Route
          path={PAGE_PATHS.FEEDBACK_SEND}
          element={
            <ProtectedRoute>
              <CreatefeedBack />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FEEDBACK_FOR_ME_BY_FORMID}
          element={
            <ProtectedRoute>
              <FeedbackForMe />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.THREE60_FEEDBACK_BY_FORMID}
          element={
            <ProtectedRoute>
              <Three60Feedback />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.THREE60_FEEDBACK_LIST}
          element={
            <ProtectedRoute>
              <Three60FeedbackList />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.PEER_FEEDBACK_LIST}
          element={
            <ProtectedRoute>
              <PeerFeedbackList />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.PEER_FEEDBACK_ITEM}
          element={
            <ProtectedRoute>
              <PeerFeedback />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FEEDBACK_FOR_ME_LIST}
          element={
            <ProtectedRoute>
              <FeedbackForMeList />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FEEDBACK_I_PROVIDED_LIST}
          element={
            <ProtectedRoute>
              <FeedbackIProvidedList />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FEEDBACK_I_PROVIDED_BY_FORMID}
          element={
            <ProtectedRoute>
              <FeedbackIProvided />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.FEEDBACK_I_PROVIDED_EDIT}
          element={
            <ProtectedRoute>
              <FeedbackIProvidedUpdate />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </LoadingWrapper>
  );
};

export default Router;
