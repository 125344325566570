import { useCallback, useEffect, useMemo, useState } from "react";

import { BackButton, Button } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { ConfirmModal } from "components/common/modals";
import { FeedbackIcon } from "components/common/ui";
import UserBriefInfo from "components/user/UserBriefInfo";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import { QuestionType } from "enums/form";
import {
  useFormBlockNavigation,
  useHttpMutationService,
  useHttpQueryService,
  useLoading,
  useNumericParam,
} from "hooks";
import AnswerItem from "pages/feedback-create/AnswerItem/AnswerItem";

import Markdown from "react-markdown";
import { getDisplayDate } from "utils/date";
import { CreateAnswerRequest, UpdateAnswerRequest } from "types/answer";
import { ValidationError } from "joi";
import { FeedbackRequestService, FeedbackService, FormService } from "services";
import { UpdateFeedBackRequest } from "types/feedback";
import { UserStatusType } from "enums/user";
import { ArrayUtil } from "utils";
import { Question } from "types/question";
import { toastError, toastSuccess } from "utils/toast";
import { FeedbackIProvidedResponse } from "responses";
import {
  DISCARD_CHANGES_MODAL_DESC,
  DISCARD_CHANGES_MODAL_KEEP_EDITING,
  DISCARD_CHANGES_MODAL_TITLE,
  DISCARD_CHANGES_MODAL_YES_BUTTON,
} from "constants/modal.constant";
import { updateFeedbackSchema } from "schemas/feedback-update.schema";
import { usePreviousPageNavigation } from "hooks/previous-page-navigation";

const FeedbackIProvidedUpdate: React.FC = () => {
  const { navigateToPreviousPage } = usePreviousPageNavigation();
  const id = useNumericParam("id");

  const {
    formIsDirty,
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  const { result: feedback, isLoading: isFeedbackLoading } =
    useHttpQueryService({
      request: () => FeedbackService.getById(id),
      requestOption: { enabled: !!id },
    });

  const { result: feedbackRequest, isLoading: isFeedbackRequestLoading } =
    useHttpQueryService({
      request: () =>
        FeedbackRequestService.getById(feedback!.feedbackRequestId),
      requestOption: { enabled: !!feedback?.feedbackRequestId },
    });

  const { result: form, isLoading: isFormLoading } = useHttpQueryService({
    request: () =>
      FormService.getFormViewById(feedback!.formId, {
        feedbackRequestId: id,
      }),
    requestOption: { enabled: !!feedback },
  });

  const [validation, setValidation] = useState<ValidationError | undefined>();
  const [answers, setAnswers] = useState<UpdateAnswerRequest[]>([]);

  const sortedQuestions = useMemo(() => {
    if (!form) return [];
    return ArrayUtil.sortAscBySequence(form.questions ?? []);
  }, [form]);

  const { mutate, isLoading: isSubmitLoading } = useHttpMutationService({
    request: (payload?: UpdateFeedBackRequest) =>
      FeedbackService.update(id, payload as UpdateFeedBackRequest),
  });

  const handleChange = (question: Question, value: string | number) => {
    const { id: questionId, questionType } = question;

    const ansClone = [...answers];
    const existingAns = ansClone.find((ans) => ans.questionId === questionId);
    const newAns: UpdateAnswerRequest = {};

    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE:
        newAns.answerOptionId = Number(value);
        break;
      case QuestionType.CHECK_BOXES:
        newAns.answerOptionId = Number(value);
        break;
      default:
        newAns.answerText = String(value);
        break;
    }

    if (existingAns) {
      Object.assign(existingAns, newAns);
    } else {
      ansClone.push({ questionId, ...newAns });
    }

    setFormIsDirty(true);
    setValidation(undefined);
    setAnswers(ansClone);
  };

  const handleSubmit = async () => {
    const payload = { answers };
    const { error } = updateFeedbackSchema.validate(payload);

    if (error) {
      setValidation(error);
      toastError(
        "Required field(s) missing. Please fill in all the required field(s) before sharing your feedback.",
      );
      return;
    }

    const response = await mutate(payload);
    if (response) {
      setFormIsDirty(false);
      toastSuccess(`Your feedback has been updated successfully`);
    } else {
      toastError(
        `There are some issues occurs while updating your feedback. Please try again.`,
      );
    }
  };

  const handleClickCancelButton = () => {
    navigateToPreviousPage();
  };

  const _getAnswerErrorMessage = useCallback(
    (index: number) => {
      let errorMessage = "";
      if (validation) {
        for (let i = 0; i < validation.details.length; i++) {
          const indexOfError = validation.details[i].path[1];
          if (indexOfError === index) {
            errorMessage = validation.details[i].message;
            break;
          }
        }
      }
      return errorMessage;
    },
    [validation],
  );

  const _getInitialAnswers = (
    feedback?: FeedbackIProvidedResponse,
  ): CreateAnswerRequest[] | null => {
    if (!feedback?.answers) return null;
    return feedback.answers.map(
      ({ answerOptionId, answerText, questionId }) => ({
        questionId,
        ...(answerOptionId && { answerOptionId }),
        ...(answerText && { answerText }),
      }),
    );
  };

  const isAnswerDifferentFromInitial = (
    initialAnswers: UpdateAnswerRequest[],
    currentAnswers: UpdateAnswerRequest[],
  ): boolean => {
    if (initialAnswers.length != currentAnswers.length) return true;

    for (let i = 0; i < currentAnswers.length; i++) {
      if (
        initialAnswers[i].answerText !== currentAnswers[i].answerText ||
        initialAnswers[i].questionId !== currentAnswers[i].questionId ||
        initialAnswers[i].answerOptionId !== currentAnswers[i].answerOptionId
      ) {
        return true;
      }
    }

    return false;
  };

  const isLoading = useLoading([
    isFeedbackLoading,
    isFeedbackRequestLoading,
    isFormLoading,
    isSubmitLoading,
  ]);

  useEffect(() => {
    const initialAnswers = _getInitialAnswers(feedback);
    if (initialAnswers) setAnswers(initialAnswers);
  }, [feedback]);

  useEffect(() => {
    if (answers.length === 0) setFormIsDirty(false);

    const areAnswersDirty = answers.some((ans) => {
      return Boolean(ans.answerOptionId) || Boolean(ans.answerText?.trim());
    });

    const initialAnswers = _getInitialAnswers(feedback);

    if (
      initialAnswers &&
      !isAnswerDifferentFromInitial(initialAnswers, answers)
    )
      setFormIsDirty(false);
    else setFormIsDirty(areAnswersDirty);
  }, [answers]);

  return (
    <Layout mainClassName="!p-0">
      <LoadingWrapper isLoading={isLoading}>
        <div className="h-full flex flex-col">
          {form && feedback && (
            <div className="flex justify-between h-fit">
              <div className="h-fit flex-auto min-h-[200px] px-8 py-8">
                <div className="mb-6 flex justify-between">
                  <div className="flex gap-6 items-center">
                    <BackButton className="text-neutral-200" />
                    <div className="flex gap-2 items-center">
                      {form?.formType && <FeedbackIcon type={form.formType} />}
                      <PageTitle className="flex items-center gap-[0.5rem]">
                        <span>{form?.name} for</span>
                        <UserBriefInfo
                          name={feedback?.reviewee.fullName ?? ""}
                          email={feedback?.reviewee.email ?? ""}
                          avatarUrl={feedback?.reviewee.avatar ?? ""}
                          status={
                            feedback?.reviewee.status ?? UserStatusType.ACTIVE
                          }
                          triggerClassName="gap-x-[0.5rem]"
                          className="text-turquoise-75 text-[24px] font-[600] leading-[31.2px]"
                        />
                      </PageTitle>
                    </div>
                  </div>
                </div>

                <div className="my-6 flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <div>
                      <span className="text-neutral-100 body-16-medium">
                        Type:&nbsp;
                      </span>
                      {form?.formType && (
                        <span className="text-neutral-150 body-16-regular">
                          {FORM_TYPE_MAP[form.formType]}
                        </span>
                      )}
                    </div>

                    {feedbackRequest?.dueDate && (
                      <>
                        <span className="text-neutral-100 body-14-regular">
                          |
                        </span>
                        <div>
                          <span className="text-neutral-100 body-16-medium">
                            Deadline:&nbsp;
                          </span>
                          <span className="text-neutral-150 body-16-regular">
                            {getDisplayDate(feedbackRequest.dueDate)}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {form?.description && (
                    <Markdown className="body-16-regular text-neutral-150">
                      {form.description}
                    </Markdown>
                  )}
                </div>
                <form
                  className="flex-1 bg-blue-10 px-8 pt-8 pb-20 rounded-3xl"
                  onSubmit={handleSubmit}
                >
                  <div className="space-y-6">
                    {sortedQuestions.map((question, index) => (
                      <AnswerItem
                        key={index}
                        answers={answers}
                        question={question}
                        onChange={handleChange}
                        errorMessage={
                          validation ? _getAnswerErrorMessage(index) : ""
                        }
                      />
                    ))}
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="sticky bottom-0 mt-auto bg-neutral-0 flex justify-end gap-4 pt-6 pb-8 px-8 border-t border-neutral-20 rounded-b-3xl">
            <Button
              variant="secondary"
              size="medium"
              label="Cancel"
              onClick={handleClickCancelButton}
            />
            <Button
              variant="primary"
              size="medium"
              disabled={!formIsDirty}
              label="Update Feedback"
              onClick={handleSubmit}
            />
          </div>
        </div>

        <ConfirmModal
          open={showLeaveModal}
          title={DISCARD_CHANGES_MODAL_TITLE}
          content={DISCARD_CHANGES_MODAL_DESC}
          yesButton={DISCARD_CHANGES_MODAL_YES_BUTTON}
          noButton={DISCARD_CHANGES_MODAL_KEEP_EDITING}
          onYes={handleConfirmLeaveModal}
          onNo={handleCancelLeaveModal}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default FeedbackIProvidedUpdate;
