import { FeedbackIProvidedResponse } from "responses";
import { useAuthStore } from "store";
import { AnswersGroupedByQuestion } from "types/answer-with-reviewer.type";
import { FormWithQuestion } from "types/form";
import FeedbackByForm from "./FeedbackByForm";
import UserBriefInfo from "components/user/UserBriefInfo";
import { Collapse } from "components/common/collapses";
import { LinkButton } from "components/common/forms";
import { DrawIcon } from "components/icons";
import { generatePath } from "react-router-dom";
import { PAGE_PATHS } from "constants/page-paths";
import { useMemo } from "react";

type FeedbackIProvidedItemProps = {
  form: FormWithQuestion;
  feedback: FeedbackIProvidedResponse;
};

const FeedbackIProvidedItem: React.FC<FeedbackIProvidedItemProps> = ({
  form,
  feedback,
}) => {
  const user = useAuthStore((state) => state.user);

  if (!user) return null;

  const {
    reviewee: { email, avatar, fullName, status },
    answers,
  } = feedback;

  const { questions, dueDate } = form;

  const answersGroupedByQuestion = answers.reduce((accumulator, answer) => {
    const { questionId } = answer;
    accumulator[questionId] = [{ answer, reviewer: user }];
    return accumulator;
  }, {} as AnswersGroupedByQuestion);

  const showEditBtn = useMemo(() => {
    return !dueDate || (dueDate && dueDate >= new Date());
  }, [dueDate]);

  return (
    <Collapse
      header={
        <div className="w-full flex items-center justify-between me-2">
          <h3 className="body-16-medium text-neutral-100 flex items-center gap-[0.25rem]">
            <span>Your feedback for</span>
            <UserBriefInfo
              name={fullName}
              email={email}
              avatarUrl={avatar || ""}
              className="text-[16px] font-[600] text-turquoise-100"
              status={status}
            />
          </h3>
          {showEditBtn && (
            <LinkButton
              to={generatePath(PAGE_PATHS.FEEDBACK_I_PROVIDED_EDIT, {
                id: feedback.id,
              })}
              size="medium"
            >
              <DrawIcon className="w-4 h-4" />
              Edit
            </LinkButton>
          )}
        </div>
      }
    >
      <div className="p-6 border-t border-neutral-25 flex flex-col gap-4">
        <FeedbackByForm
          questions={questions}
          answersGroupedByQuestion={answersGroupedByQuestion}
          className="px-6 py-4"
        />
      </div>
    </Collapse>
  );
};

export default FeedbackIProvidedItem;
